@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,500&family=Rubik&family=Work+Sans&display=swap");

* {
  font-family: "Roboto", sans-serif;
  user-select: none;
}

.App {
  text-align: center;
}

.table-bordered {
  border: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border-left: 2px solid #dee2e6;
  border-right: 2px solid #dee2e6;
}
